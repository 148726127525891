import React from "react"

//components
import useMediaQuery from "../useMediaQuery"
import SearchSuggestions from "./SearchSuggestions"

const SearchSuggestionWrapper = ({ courses, subjects }) => {
  const isMobile = useMediaQuery("(max-width: 600px)")
  return <SearchSuggestions isMobile={isMobile} courses={courses} subjects={subjects} />
}

export default SearchSuggestionWrapper
