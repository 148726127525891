import React from "react"
import "./index.css"
import "bootstrap/dist/css/bootstrap.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { createStore, applyMiddleware } from "redux"
import { Provider } from "react-redux"
import rootReducer from "./reducers/rootReducer"
import thunk from "redux-thunk"
import { createFirestoreInstance } from "redux-firestore"
import { ReactReduxFirebaseProvider, getFirebase, useFirestore } from "react-redux-firebase"
import { useSelector } from "react-redux"
import { isLoaded } from "react-redux-firebase"
import { composeWithDevTools } from "redux-devtools-extension"
import { hydrate, render } from "react-dom"

// contexts
import { AuthProvider } from "./contexts/AuthContext"

// components
import SuspenseFallback from "./components/SuspenseFallback"

// Firebase
import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/analytics"

export const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
})

export const analytics = firebase.analytics()

export const auth = app.auth()

export const db = firebase.firestore()

export default firebase

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk.withExtraArgument({ getFirebase, useFirestore }))))

const rrfProps = {
  firebase,
  config: {},
  dispatch: store.dispatch,
  createFirestoreInstance
}

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) {
    return <SuspenseFallback />
  }
  return children
}

const APP = (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <AuthIsLoaded>
        <React.StrictMode>
          <AuthProvider>
            <App />
          </AuthProvider>
        </React.StrictMode>
      </AuthIsLoaded>
    </ReactReduxFirebaseProvider>
  </Provider>
)

// react-snap
const rootElement = document.getElementById("root")
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement)
} else {
  render(APP, rootElement)
}

reportWebVitals()
