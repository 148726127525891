import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const MetaDecorator = ({ title, description, imgUrl, imgAlt, school, isCourse }) => {
  const courseTitle = isCourse ? `Curso: ${title} - ${school} na Apick` : title

  return (
    <Helmet>
      <title>Apick | {title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={courseTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imgUrl} />
      <meta property="og:url" content={imgUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:alt" content={imgAlt} />
    </Helmet>
  )
}

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imgUrl: PropTypes.string,
  imgAlt: PropTypes.string
}

export default MetaDecorator
