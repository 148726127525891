export const toggleSearchBar = () => {
  return {
    type: "TOGGLE_SEARCHBAR"
  }
}

export const closeSearchBar = () => {
  return {
    type: "CLOSE_SEARCHBAR"
  }
}

export const setCurrentQuery = query => {
  return {
    type: "SET_CURRENT_QUERY",
    payload: query
  }
}

export const hideSearchBarHeader = () => {
  return {
    type: "HIDE_SEARCHBAR_HEADER"
  }
}

export const showSearchBarHeader = () => {
  return {
    type: "SHOW_SEARCHBAR_HEADER"
  }
}
