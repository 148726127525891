import React from "react"
import { Link } from "react-router-dom"

//style
import styles from "./HeaderLoggedOut.module.scss"

export default function HeaderLoggedOut() {
  return (
    <div className={styles.container}>
      <Link to="/entrar">
        <button className="btn btn--secondary">Entrar</button>
      </Link>
      <Link to="/criar-conta">
        <button className={`btn btn--primary ${styles.new_account_btn}`}>Criar Conta</button>
      </Link>
    </div>
  )
}
