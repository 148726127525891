const courses = (state = [], action) => {
  switch (action.type) {
    case "FETCH_COURSES":
      return action.payload
    case "FETCH_COURSES_ERROR":
      console.log("Fetching courses collections was not possible, there an error:", action.error)
      return state
    default:
      return state
  }
}

export const currentSubject = (state = "", action) => {
  switch (action.type) {
    case "SET_CURRENT_SUBJECT":
      return action.payload ? action.payload : ""
    case "SET_CURRENT_SUBJECT_ERROR":
      console.log("Changing current subject was not possible, there an error: ", action.error)
      break
    default:
      return state
  }
}

export const matchCourses = (state = [], action) => {
  switch (action.type) {
    case "SET_MATCH_COURSES":
      return action.payload
    case "SET_MATCH_COURSES_ERROR":
      console.log("Setting match courses was not possible, there an error: ", action.error)
      break
    default:
      return state
  }
}

export const courseOnCart = (state = null, action) => {
  switch (action.type) {
    case "SET_COURSE_ON_CART":
      return action.payload
    case "SET_COURSE_ON_CART_ERROR":
      console.log("Setting courseOnCart was not possible, there an error: ", action.error)
      break
    default:
      return state
  }
}

export default courses
