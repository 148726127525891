const favorite = (state = false, action) => {
  switch (action.type) {
    case "ADD_COURSE_TO_FAVORITES":
      return true
    default:
      return state
  }
}

export default favorite
