import React from "react"
import { Link, useHistory } from "react-router-dom"
import WhatsAppWidget from "react-whatsapp-widget"
import "react-whatsapp-widget/dist/index.css"

//styles
import styles from "./Footer.module.scss"

//images
import Logo from "../../assets/logos/logo.svg"
import WhatsApp from "../../assets/icons/whatsapp.png"

const Footer = () => {
  const categories = [
    { title: "Gestão de Projetos e Análise de Dados", link: "/pesquisa/Gestão%20de%20Projetos%20e%20Análise%20de%20Dados" },
    { title: "Liderança e Desenvolvimento Pessoal", link: "/pesquisa/Liderança%20e%20Desenvolvimento%20Pessoal" },
    { title: "Inovação e Produtos Digitais", link: "/pesquisa/Inovação%20e%20Produtos%20Digitais" },
    { title: "Negociação e Vendas", link: "/pesquisa/Negociação%20e%20Vendas" }
  ]
  const partners = ["Aldeia", "Datalege", "Escola Conquer", "Wallstreet", "Mentorama"]
  const history = useHistory()

  const handleCategory = () => {
    history.push("/")
    window.scrollTo({
      top: 300,
      left: 0,
      behavior: "smooth"
    })
  }

  return (
    <div>
      {/* Upper Footer */}
      <section className={styles.upper_container}>
        {/* Logo & Slogan */}

        <div className={styles.logo_container}>
          <div className={styles.logo}>
            <Link to="/">
              <img src={Logo} alt="logo apick" className={styles.logo__img} />
            </Link>
          </div>
          <p className={styles.slogan}>
            Compare cursos e faça <strong>a melhor escolha</strong>
          </p>
          <div className={styles.whatsapp}>
            <WhatsAppWidget phoneNumber="5541992698565" textReplyTime="Normalmente responde no mesmo dia" companyName="Suporte Apick" sendButton="Enviar" message="Olá! Como posso te ajudar?" placeholder="Digite uma mensagem" />
          </div>
        </div>

        {/* Lists */}
        <div className={styles.category_partners_container}>
          <div className={styles.list_container}>
            <ul className={styles.list}>
              <p className={styles.list__title} onClick={handleCategory}>
                Categorias
              </p>

              {categories.map((category, index) => {
                return (
                  <Link to={category.link} key={index}>
                    <li className={styles.list__item}>{category.title}</li>
                  </Link>
                )
              })}
            </ul>
          </div>

          <div className={styles.list_container}>
            <ul className={styles.list}>
              <Link to="/escolas">
                <p className={styles.list__title}>Escolas</p>
              </Link>
              {partners.sort().map((partner, index) => {
                return (
                  <Link to="/escolas" key={index}>
                    <li className={styles.list__item}>{partner}</li>
                  </Link>
                )
              })}
              <Link to="/escolas">
                <li className={styles.list__item}>Mais...</li>
              </Link>
            </ul>
          </div>
        </div>

        <div className={`${styles.about_contact_container} ${styles.list_container}`}>
          <ul className={styles.list}>
            <Link to="/sobre">
              <p className={styles.list__title}>Empresa</p>
            </Link>
            <Link to="/sobre">
              <li className={styles.list__item}>Sobre</li>
            </Link>
            <Link to="/blog">
              <li className={styles.list__item}>Blog</li>
            </Link>
          </ul>
          <ul className={styles.list}>
            <p className={styles.list__title}>Nos Siga</p>

            <a href="https://www.instagram.com/apick.cursos/">
              <li className={styles.list__item}>Instagram</li>
            </a>
          </ul>
        </div>
      </section>

      {/* Lower Footer */}

      <footer>
        <div className={styles.legal}>
          <div className={styles.copyright}>
            <span>Apick Provedor de Conteúdo Tecnológico LTDA.</span>
            <p className="m-0">CNPJ 40.752.820/0001-54</p>{" "}
          </div>
          <div className={styles.terms_privacy_container}>
            <Link to="/termos-de-servico">
              <p className={styles.terms}>Termos de Serviço</p>
            </Link>
            <Link to="/politica-de-privacidade">
              <p className={styles.privacy}>Política de Privacidade</p>
            </Link>
          </div>
          <div className={styles.terms_privacy_container_mobile}>
            <p className={styles.terms_privacy_mobile}>
              <Link to="/termos-de-servico">Termos</Link> e <Link to="/politica-de-privacidade">Políticas</Link>
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
