import { combineReducers } from "redux"
import { firebaseReducer } from "react-redux-firebase"
import { firestoreReducer } from "redux-firestore"

// reducers
import counter from "./counterReducer"
import isLogged from "./isLoggedReducer"
import error from "./errorReducer"
import loading from "./loadingReducer"
import mode from "./modeReducer"
import courses, { currentSubject, matchCourses, courseOnCart } from "./coursesReducer"
import favorite from "./favoriteReducer"
import searchbar, { searchbarHeader } from "./searchbarReducer"
import popup from "./popupReducer"
import query from "./queriesReducer"

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  counter,
  isLogged,
  error,
  loading,
  mode,
  courses,
  currentSubject,
  courseOnCart,
  favorite,
  searchbar,
  searchbarHeader,
  popup,
  query,
  matchCourses
})

export default rootReducer
