import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

//context
import { useAuth } from "../../contexts/AuthContext"

//images
import Logo from "../../assets/logos/logo.svg"

//components
import HeaderLoggedOut from "./HeaderLoggedOut"
import HeaderLoggedIn from "./HeaderLoggedIn"
import SearchBar from "../LandingPage/SearchBar"

//style
import styles from "./HeaderDesktop.module.scss"

const HeaderDesktop = ({ searchbarHeader, courses, subjects }) => {
  const { currentUser } = useAuth()

  return (
    <>
      <div className={styles.header}>
        <Link to="/">
          <div className={styles.logo}>
            <img className={styles.logo_img} src={Logo} alt="logo apick" />
          </div>
        </Link>
        <div style={{ position: "relative" }}>
          {searchbarHeader && (
            <div>
              <SearchBar header={true} courses={courses} subjects={subjects} />
            </div>
          )}
        </div>

        {currentUser ? <HeaderLoggedIn /> : <HeaderLoggedOut />}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    searchbarHeader: state.searchbarHeader
  }
}

export default connect(mapStateToProps)(HeaderDesktop)
