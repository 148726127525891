import React, { useEffect } from "react"
import { connect } from "react-redux"

//redux
import { toggleSearchBar, showSearchBarHeader, hideSearchBarHeader } from "../../actions/searchbarActions"

//components
import useMediaQuery from "../useMediaQuery"
import SearchBar from "./SearchBar"
import CategoryIcons from "./CategoryIcons"
import Jumbo from "./Jumbo"
import Hero from "./Hero"

//styles
import styles from "./LandingPage.module.scss"

function LandingPage({ toggleSearchBar, courses, subjects, hideSearchBarHeader, showSearchBarHeader }) {
	const isMobile = useMediaQuery("(max-width: 600px)")

	useEffect(() => {
		hideSearchBarHeader()
		return function cleanup() {
			showSearchBarHeader()
		}
	}, [])

	return (
		<div>
			{isMobile && (
				<>
					<Hero toggleSearchBar={toggleSearchBar} />
					<div className={styles.categories_container}>
						<CategoryIcons subjects={subjects} />
					</div>
				</>
			)}

			{!isMobile && (
				<div className={styles.mainPageContainer}>
					<SearchBar isMobile={isMobile} courses={courses} header={false} toggleSearchBar={toggleSearchBar} subjects={subjects} />
					<div className={styles.categories_container}>
						<CategoryIcons subjects={subjects} />
					</div>
				</div>
			)}

			<Jumbo isMobile={isMobile} />
		</div>
	)
}

const mapStateToProps = state => {
	return {
		searchbar: state.searchbar,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		toggleSearchBar: () => dispatch(toggleSearchBar()),
		hideSearchBarHeader: () => dispatch(hideSearchBarHeader()),
		showSearchBarHeader: () => dispatch(showSearchBarHeader()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage)
