const popup = (state = -1, action) => {
  switch (action.type) {
    case "TOGGLE_INSTRUCTOR_POPUP":
      console.log(action.index, "from reducer")
      return action.index
    case "TOGGLE_SCHOOL_POPUP":
      return 101
    case "CLOSE_POPUP":
      return -1
    default:
      return state
  }
}

export default popup
