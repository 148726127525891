const query = (state = '', action) => {
  switch (action.type) {
    case 'SET_CURRENT_QUERY':
      return action.payload
    case 'SET_CURRENT_QUERY_ERROR':
      console.log(
        'Setting current query was not possible, there an error:',
        action.error
      )
      break
    default:
      return state
  }
}

export default query
