export const addCourseToFavorites = async (course, user) => {
  return (dispatch, getState, useFirestore) => {
    // make async call to database
    console.log("Action called")

    const firestore = useFirestore()
    const res = firestore.collection("users").doc(user.id).get()
    const user = res.data()
    const prevFavoriteCourses = user.favoriteCourses
    console.log(prevFavoriteCourses)
    const newFavoriteCourses = [...prevFavoriteCourses, course.id]
    user
      .set({ favoriteCourses: [newFavoriteCourses] })
      .then(() => {
        dispatch({ type: "ADD_COURSE_TO_FAVORITES" })
      })
      .catch(error => {
        dispatch({ type: "ADD_COURSE_TO_FAVORITES_ERROR", error })
      })
  }
}

export const setCurrentCourse = course => {
  return (dispatch, getState, getFirebase) => {
    // make async call to database
    // const firebase = getFirebase()
    // firebase
    //   .collection('queries')
    //   .add()
    //    .then(function (doc) {
    //     dispatch({ type: 'SET_CURRENT_COURSE', doc })
    //   })
    //   .catch(error => {
    //     dispatch({ type: 'SET_CURRENT_COURSE_ERROR', error })
    try {
      dispatch({ type: "SET_CURRENT_COURSE", course })
    } catch (err) {
      dispatch({ type: "SET_CURRENT_COURSE_ERROR", err })
    }
  }
}

export const setCurrentSubject = subject => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "SET_CURRENT_SUBJECT", payload: subject })
  }
}

export const setMatchCourses = matchCourses => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "SET_MATCH_COURSES", payload: matchCourses })
  }
}

export const setCourseOnCart = course => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "SET_COURSE_ON_CART", payload: course })
  }
}
