import React, { useRef } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import disableScroll from "disable-scroll"

//redux
import { toggleSearchBar } from "../../actions/searchbarActions"
import { closeSearchBar } from "../../actions/searchbarActions"

//components
import SearchBar from "../LandingPage/SearchBar"

//style
import styles from "./HeaderMobile.module.scss"

//images
import Logo from "../../assets/logos/logo.svg"

//icons
import { AiOutlineSearch } from "react-icons/ai"
import { AiOutlineMenu } from "react-icons/ai"

function HeaderMobile({ searchbar, toggleSearchBar, closeSearchBar, courses, subjects }) {
  const menu = useRef()

  function toggleMenu() {
    menu.current.classList.toggle("invisible--mobile")
    closeSearchBar()
  }

  function toggleSearch() {
    window.scrollTo(0, 0)
    toggleSearchBar()
  }

  function closeEverything() {
    closeSearchBar()
    menu.current.classList.add("invisible--mobile")
    disableScroll.off()
  }

  searchbar && disableScroll.on()
  !searchbar && disableScroll.off()

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <AiOutlineMenu className={styles.icons} onClick={toggleMenu} />
        <Link to="/">
          <img src={Logo} alt="Apick Logo" className={styles.logo} onClick={closeEverything} />
        </Link>
        <AiOutlineSearch className={styles.icons} onClick={toggleSearch} />
      </div>

      {/* mobile menu */}
      <div className="menu--mobile invisible--desktop invisible--mobile" ref={menu} onClick={closeEverything}>
        <ul>
          <Link to="/sobre">
            <li>Sobre</li>
          </Link>
          <Link
            to="/"
            onClick={() =>
              window.scrollTo({
                top: 400,
                left: 0,
                behavior: "smooth"
              })
            }
          >
            <li>Categorias</li>
          </Link>
          <Link to="/entrar">
            <li>Entrar</li>
          </Link>
          <Link to="/criar-conta">
            <li>Criar Conta</li>
          </Link>
        </ul>
      </div>

      {/* mobile search bar */}

      {searchbar && <SearchBar menu={menu} courses={courses} subjects={subjects} />}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    searchbar: state.searchbar
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleSearchBar: () => dispatch(toggleSearchBar()),
    closeSearchBar: () => dispatch(closeSearchBar())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMobile)
