import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useAuth } from "../../contexts/AuthContext"
import firebase from "../../index"

//style
import styles from "./HeaderLoggedIn.module.scss"

export default function HeaderLoggedIn(props) {
  const { currentUser } = useAuth()
  const [user, setUser] = useState({})

  useEffect(() => {
    var userRef = firebase.firestore().collection("users").doc(currentUser.uid)

    userRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setUser(doc.data())
        } else {
          console.log("No such document!")
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error)
      })
  }, [currentUser])

  return (
    <div className={styles.userInfo}>
      <div className={styles.userFavorites}>
        <Link to="/perfil">Meus Cursos Favoritos</Link>
      </div>
      <div className={styles.userAvatar}>
        <Link to="/perfil">
          <div className={styles.background}>
            <p className={styles.initials}>{user.initials}</p>
          </div>
        </Link>
      </div>
    </div>
  )
}
