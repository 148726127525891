import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import disableScroll from "disable-scroll"
import { useFirestore } from "react-redux-firebase"
import { Alert } from "react-bootstrap"

//style
import styles from "./SearchBar.module.scss"

//redux
import { closeSearchBar } from "../../actions/searchbarActions"
import { setCurrentSubject } from "../../actions/courseActions"
import { setMatchCourses } from "../../actions/courseActions"
import { setCurrentQuery } from "../../actions/searchbarActions"

//images
import Logo from "../../assets/logos/logo.svg"

//icons
import { AiOutlineSearch } from "react-icons/ai"
import { CgClose } from "react-icons/cg"

//components
import SearchSuggestionsWrapper from "../SearchSuggestions/SearchSuggestionsWrapper"

const SearchBar = ({ closeSearchBar, courses, query, header, subjects }) => {
  const history = useHistory()
  const firestore = useFirestore()

  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState("")

  /* OnClick: Search Button */

  async function handleSearchButton() {
    setIsLoading(true)

    if (query == "") {
      setIsLoading(false)

      return
    }
    if (query != "") {
      closeSearchBar()
      setCurrentSubject(null)
      disableScroll.off()
      await firestore.collection("queries").add({ content: query, createdAt: new Date() })
      // case a Course Title EQUALS query
      let queryToCourse = courses.find(course => {
        return course.title === query
      })

      if (queryToCourse) {
        let courseToId = queryToCourse.id
        // redirect straight to Course Detail Page
        setIsLoading(false)
        history.push("/cursos/" + courseToId)
        return
      } else {
        // else SearchResult component will process it
        setIsLoading(false)
        history.push(`/pesquisa/${query}`)
      }
    }
  }

  const containerStyle = header ? `${styles.tempSearchBar} ${styles.mainContainer} ${styles.boxHeader} popup--mobile-inner btn--secondary` : `${styles.tempSearchBar} ${styles.mainContainer} ${styles.boxLanding} popup--mobile-inner`

  const btnStyle = header ? styles.searchBtnHeader : styles.searchBtn

  const iconStyle = header ? styles.searchIconHeader : styles.searchIcon

  const logoSloganSearchbarStyle = header ? "popup--mobile" : "logo-slogan-searchbar popup--mobile"

  const inputContainer = header ? `${styles.input_container} ${styles.input_container_header}` : styles.input_container

  return (
    <div className={logoSloganSearchbarStyle}>
      {!header && (
        <>
          <div className="logo">
            <img src={Logo} alt="logo apick" />
          </div>
          <div className="slogan">
            Compare cursos e faça <strong>a melhor escolha</strong>
          </div>
        </>
      )}

      <div className={containerStyle}>
        {message && (
          <Alert variant="danger" className={styles.alert}>
            {message}
          </Alert>
        )}
        <CgClose className="login-close-icon" style={{ fontSize: "1rem" }} onClick={closeSearchBar} />
        <form action="submit" className={inputContainer}>
          <label className={styles.singleInput}>
            {!header && (
              <>
                <p className={styles.inputTitle}>O que você quer estudar?</p>
                <SearchSuggestionsWrapper header={false} courses={courses} subjects={subjects} />
              </>
            )}
            {header && <SearchSuggestionsWrapper header={true} courses={courses} subjects={subjects} />}
          </label>

          <button type="submit" className={btnStyle} disabled={isLoading} onClick={handleSearchButton}>
            <AiOutlineSearch className={iconStyle} />

            <p className={`invisible--desktop ${styles.buscar}`}>Buscar</p>
          </button>
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    query: state.query || []
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchBar: () => dispatch(closeSearchBar()),
    setCurrentSubject: subject => dispatch(setCurrentSubject(subject)),
    setMatchCourses: matchCourses => dispatch(setMatchCourses(matchCourses)),
    setCurrentQuery: inputValue => dispatch(setCurrentQuery(inputValue))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)
