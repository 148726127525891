import React from "react"
import { useHistory } from "react-router-dom"

//style
import styles from "./CategoryIcons.module.scss"

const CategoryIcons = ({ subjects }) => {
	const history = useHistory()

	return (
		<>
			<div className={styles.container}>
				<ul className={styles.grid}>
					{subjects.map(subject => {
						return (
							<li className={styles.single} onClick={() => history.push(`/pesquisa/${subject.category}`)} key={subject.category}>
								<div className={styles.info} key={subject.category}>
									<div className={styles.image}>
										<img src={subject.imgUrl} alt={subject.alt} />
									</div>
									<p className={styles.title}>{subject.category}</p>
								</div>
							</li>
						)
					})}
				</ul>
			</div>
		</>
	)
}

export default CategoryIcons
