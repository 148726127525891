export default function searchbar(state = false, action) {
  switch (action.type) {
    case "TOGGLE_SEARCHBAR":
      return !state
    case "CLOSE_SEARCHBAR":
      return false
    default:
      return state
  }
}

export function searchbarHeader(state = true, action) {
  switch (action.type) {
    case "HIDE_SEARCHBAR_HEADER":
      return false
    case "SHOW_SEARCHBAR_HEADER":
      return true
    default:
      return state
  }
}
