const mode = (state = [], action) => {
  switch (action.type) {
    case 'SET_MODE':
      return [...state, action.payload]
    default:
      return [...state]
  }
}

export default mode
