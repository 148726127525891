import React from "react"

//components
import HeaderMobile from "./HeaderMobile"
import HeaderDesktop from "./HeaderDesktop"
import useMediaQuery from "../useMediaQuery"

const Header = ({ courses, subjects }) => {
  const isMobile = useMediaQuery("(max-width: 600px)")

  return <div>{isMobile ? <HeaderMobile courses={courses} subjects={subjects} /> : <HeaderDesktop courses={courses} subjects={subjects} />}</div>
}

export default Header
