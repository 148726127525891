import React, { lazy, Suspense } from "react"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { compose } from "redux"
import { connect } from "react-redux"
import { firestoreConnect } from "react-redux-firebase"
import "bootstrap/dist/css/bootstrap.min.css"

//style
import "./App.scss"

//contexts
import { useAuth } from "./contexts/AuthContext"

//components
import Footer from "./components/Footer/Footer"
import LandingPage from "./components/LandingPage/LandingPage"
import ScrollToTop from "./components/ScrollToTop"
import Header from "./components/Header/Header"
import MetaDecorator from "./components/MetaDecorator"
import SuspenseFallback from "./components/SuspenseFallback"
// lazy-loaded components
const Blog = lazy(() => import("./components/Blog"))
const About = lazy(() => import("./components/About/About"))
const LogIn = lazy(() => import("./components/LogIn"))
const CreateAccount = lazy(() => import("./components/CreateAccount"))
const Profile = lazy(() => import("./components/Profile/Profile"))
const PrivateRoute = lazy(() => import("./components/PrivateRoute"))
const ForgotPassword = lazy(() => import("./components/ForgotPassword"))
const UpdateProfile = lazy(() => import("./components/UpdateProfile"))
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"))
const Schools = lazy(() => import("./components/Schools"))
const SearchResultWrapper = lazy(() => import("./components/SearchResult/SearchResultWrapper"))
const TermsOfService = lazy(() => import("./components/TermsOfService"))
const CourseDetailWrapper = lazy(() => import("./components/CourseDetail/CourseDetailWrapper"))
const NotFound = lazy(() => import("./components/NotFound"))
const PaymentForm = lazy(() => import("./components/Payment/PaymentForm"))

function App(props) {
	const { currentUser } = useAuth()
	return (
		<>
			<MetaDecorator title={"Buscador de Cursos"} description={"Busque, compare e faça a melhor escolha com a Apick"} />
			<div className="body">
				<Router>
					<ScrollToTop>
						<Header courses={props.courses} subjects={props.subjects} />
						<Switch>
							<Route exact path="/">
								<LandingPage courses={props.courses} subjects={props.subjects} />
							</Route>
							<Suspense fallback={<SuspenseFallback />}>
								<Route exact path="/pesquisa/:query">
									<SearchResultWrapper courses={props.courses} subjects={props.subjects} users={props.users} instructors={props.instructors} schools={props.schools} />
								</Route>
								<Route exact path="/cursos/:course_id">
									<CourseDetailWrapper courses={props.courses} subjects={props.subjects} users={props.users} instructors={props.instructors} schools={props.schools} />
								</Route>
								{/* <Route exact path="/pagamento">
                  <PaymentForm users={props.users} />
                </Route> */}
								<Route path="/destaque">
									<Redirect to="/cursos/ywc5m8NTetczgVP8t2P9" />
								</Route>
								<Route path="/termos-de-servico">
									<TermsOfService />
								</Route>
								<Route path="/politica-de-privacidade">
									<PrivacyPolicy />
								</Route>
								<Route path="/escolas">
									<Schools />
								</Route>
								<Route path="/blog">
									<Blog />
								</Route>
								<Route path="/sobre">
									<About />
								</Route>
								<Route path="/entrar">
									<LogIn />
								</Route>
								<Route path="/criar-conta">
									<CreateAccount />
								</Route>
								<Route path="/esqueceu-senha" component={ForgotPassword} />
								<Route path="/404" component={NotFound} />

								<Route path="/perfil">{currentUser ? <Profile courses={props.courses} users={props.users} /> : <Redirect to="/entrar" />}</Route>
								<PrivateRoute path="/editar-perfil" component={UpdateProfile} />
							</Suspense>
						</Switch>
						<Footer />
					</ScrollToTop>
				</Router>
			</div>
		</>
	)
}

const mapStateToProps = state => {
	return {
		instructors: state.firestore.ordered.instructors || [],
		schools: state.firestore.ordered.schools || [],
		courses: state.firestore.ordered.courses || [],
		subjects: state.firestore.ordered.subjects || [],
		users: state.firestore.ordered.users || [],
	}
}

export default compose(connect(mapStateToProps), firestoreConnect([{ collection: "schools" }, { collection: "courses" }, { collection: "instructors" }, { collection: "subjects" }, { collection: "users" }]))(App)
