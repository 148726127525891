import React, { Component } from "react"
import { connect } from "react-redux"

//redux
import { setCurrentQuery, setInputValue } from "../../actions/searchbarActions"

//style
import styles from "./SearchSuggestions.module.scss"

class SearchSuggestions extends Component {
  state = {
    titleSuggestions: [],
    categorySuggestions: [],
    subcategorySuggestions: [],
    numberOfSuggestions: 0,
    inputValue: ""
  }

  subcategories = []

  onTextChanged = e => {
    const input = e.target.value
    this.props.setCurrentQuery(input)
    this.setState({ inputValue: input })
    // if user does not type anything or deletes input, clear suggestions
    if (input.length === 0) {
      this.setState(() => ({
        titleSuggestions: [],
        categorySuggestions: [],
        subcategorySuggestions: []
      }))
    }
    // if user types more than 4 letters, search for
    if (input.length > 4) {
      // Part of Course Title
      const titles = []
      this.props.courses.forEach(course => {
        if (course.title) {
          titles.push(course.title)
        }
      })
      // console.log(titles)
      // console.log(input)
      let filteredSuggestionsTitle = titles.filter(v => v.toLowerCase().includes(input.toLowerCase()))
      let filteredSuggestions = this.props.courses.filter(course => {
        return filteredSuggestionsTitle.includes(course.title)
      })
      if (this.props.isMobile && filteredSuggestionsTitle.length > 1) {
        this.setState(() => ({ numberOfSuggestions: filteredSuggestions.length }))
      }
      this.setState(() => ({ titleSuggestions: filteredSuggestions }))
      if (this.props.isMobile && filteredSuggestionsTitle.length > 0) {
        this.setState(() => ({ titleSuggestions: [filteredSuggestions[0]] }))
      }

      // Part of a Category
      const categories = []

      this.props.subjects.forEach(subject => {
        if (subject.category) {
          categories.push(subject.category)
        }
      })
      let filteredSuggestionsCategory = categories.filter(v => v.toLowerCase().includes(input.toLowerCase()))
      this.setState(() => ({
        categorySuggestions: filteredSuggestionsCategory
      }))
      if (this.props.isMobile && filteredSuggestionsCategory.length > 0) {
        this.setState(() => ({
          categorySuggestions: [filteredSuggestionsCategory[0]]
        }))
      }

      // Part of a Subcategory
      let subcategoriesArrays = this.props.subjects.map(subjects => {
        return subjects.subcategories
      })
      this.subcategories = subcategoriesArrays.flat(1).filter(v => v !== undefined)
      let filteredSuggestionsSubcategory = this.subcategories.filter(v => v.toLowerCase().includes(input.toLowerCase()))
      this.setState(() => ({
        subcategorySuggestions: filteredSuggestionsSubcategory
      }))
      if (this.props.isMobile && filteredSuggestionsSubcategory.length > 0) {
        this.setState(() => ({
          subcategorySuggestions: [filteredSuggestionsSubcategory[0]]
        }))
      }

      if (this.props.isMobile && !filteredSuggestionsCategory.length > 0 && !filteredSuggestionsSubcategory.length > 0 && filteredSuggestionsTitle.length > 1) {
        this.setState(() => ({ titleSuggestions: [filteredSuggestions[0], filteredSuggestions[1]] }))
      }
    }
  }

  cleanTitleSuggestions() {
    this.setState(() => ({ titleSuggestions: [] }))
  }

  cleanCategorySuggestions() {
    this.setState(() => ({ categorySuggestions: [] }))
  }

  cleanSubcategorySuggestions() {
    this.setState(() => ({ subcategorySuggestions: [] }))
  }

  cleanAllSuggestions() {
    this.setState(() => ({ titleSuggestions: [], categorySuggestions: [], subcategorySuggestions: [] }))
  }

  abbreviateLongWords(sentence) {
    //check if letter is vowel
    const isVowel = letter => {
      let result = Boolean
      if (letter == "A" || letter == "E" || letter == "I" || letter == "O" || letter == "U") {
        result = true
      } else {
        result = false
      }
      return result
    }
    // conditions to reduce words or keep them original
    if (sentence.length < 12) {
      return sentence
    } else {
      const sentenceWords = sentence.split(" ")
      const reduceLongWords = sentenceWords.map(word => {
        if (word.length > 14) {
          if (!isVowel(word[6])) {
            return `${word.substring(0, 6)}.`
          } else if (!isVowel(word[7])) {
            return `${word.substring(0, 7)}.`
          } else {
            return `${word.substring(0, 8)}.`
          }
        } else if (word.length > 10) {
          if (!isVowel(word[3])) {
            return `${word.substring(0, 3)}.`
          } else if (!isVowel(word[4])) {
            return `${word.substring(0, 4)}.`
          } else if (!isVowel(word[5])) {
            return `${word.substring(0, 5)}.`
          } else {
            return `${word.substring(0, 6)}.`
          }
        } else {
          return word
        }
      })
      const reassembleWords = reduceLongWords.join(" ")
      return reassembleWords
    }
  }

  handleSelectedSuggestion = e => {
    let chosenSuggestionTitle = e.currentTarget.children[0].innerHTML
    this.setState({
      categorySuggestions: [],
      titleSuggestions: [],
      subcategorySuggestions: [],
      inputValue: chosenSuggestionTitle
    })
    this.props.dispatch(setCurrentQuery(chosenSuggestionTitle))
  }

  listStyle = () => {
    const { categorySuggestions, subcategorySuggestions, titleSuggestions } = this.state
    const suggestionsLength = categorySuggestions.length + subcategorySuggestions.length + titleSuggestions.length
    // console.log(suggestionsLength)
    if (this.props.isMobile) {
      return styles.list
    } else if (suggestionsLength < 5) {
      return this.props.header ? `${styles.list} ${styles.list_header}` : styles.list
    } else {
      return this.props.header ? `${styles.list} ${styles.list_header} ${styles.long_list}` : `${styles.list} ${styles.long_list}`
    }
  }

  // Suggests Course by Title
  renderTitleSuggestions() {
    const { titleSuggestions } = this.state
    if (titleSuggestions.length === 0) {
      return null
    }
    return (
      <>
        {titleSuggestions.map(suggestion => {
          return (
            <li key={suggestion.id} onClick={this.handleSelectedSuggestion} className={styles.item}>
              <p className={styles.title}>{suggestion.title}</p>
              <div className={styles.subject}>
                <p className={styles.category}>
                  <strong>Curso</strong> | {this.abbreviateLongWords(suggestion.category)}
                </p>
                <p className={styles.margin}>{">"}</p>
                <p className={styles.category}>{this.abbreviateLongWords(suggestion.subcategory)}</p>
              </div>
            </li>
          )
        })}
      </>
    )
  }

  // Suggests Category
  renderCategorySuggestions() {
    const { categorySuggestions } = this.state
    if (categorySuggestions.length === 0) {
      return null
    }
    return categorySuggestions.map(suggestion => {
      return (
        <li onClick={this.setValue} className={styles.item} onClick={this.handleSelectedSuggestion}>
          <p className={styles.title}>{suggestion}</p>
          <div className={styles.categorySuggestion}>
            <p className={styles.category}>Categoria</p>
            <p className={styles.margin}></p>
          </div>
        </li>
      )
    })
  }

  // Suggests Subcategory
  renderSubcategorySuggestions() {
    const { subcategorySuggestions } = this.state
    if (subcategorySuggestions.length === 0) {
      return null
    }

    const subcategoryParent = subcategory => {
      const subcategorySubject = this.props.subjects.find(subject => {
        return subject.subcategories.includes(subcategory)
      })
      if (subcategorySubject) {
        return subcategorySubject.category
      } else {
        return null
      }
    }

    return subcategorySuggestions.map(suggestion => {
      return (
        <li onClick={this.setValue} className={styles.item} onClick={this.handleSelectedSuggestion}>
          <p className={styles.title}>{suggestion}</p>
          <div className={styles.categorySuggestion}>
            <p className={styles.category}>Subcategoria de {subcategoryParent(suggestion)}</p>
            <p className={styles.margin}></p>
          </div>
        </li>
      )
    })
  }

  // Suggests "See all" option if isMobile restricted viewport
  renderMobile() {
    if (!this.props.isMobile) {
      return null
    }
    const { numberOfSuggestions } = this.state
    return (
      <li onClick={this.setValue} className={styles.item} onClick={this.handleSelectedSuggestion}>
        <p className={styles.title}>{this.state.inputValue}</p>
        <div className={styles.categorySuggestion}>
          <p className={styles.category}>Ver todos os {`${numberOfSuggestions} `}resultados</p>
          <p className={styles.margin}></p>
        </div>
      </li>
    )
  }

  inputStyle = this.props.header ? `${styles.input} ${styles.inputHeader}` : styles.input

  //The time suggestions are on the screen before being automatically erased
  timeOne = (this.state.titleSuggestions.length + this.state.categorySuggestions.length + this.state.subcategorySuggestions.length) * 1000
  timeTwo = 10000
  timeToCleanSuggestions = this.timeOne > this.timeTwo ? this.timeOne : this.timeTwo

  render() {
    if (this.renderTitleSuggestions() || this.renderCategorySuggestions() || this.renderSubcategorySuggestions()) {
      setTimeout(this.cleanAllSuggestions.bind(this), this.timeToCleanSuggestions)
    }
    return (
      <div className={styles.container}>
        <input type="text" onChange={this.onTextChanged} className={this.inputStyle} placeholder="Pesquise por título ou assunto" id={this.props.id} value={this.state.inputValue.length > 0 ? this.state.inputValue : ""} />
        {(this.renderTitleSuggestions() || this.renderCategorySuggestions() || this.renderSubcategorySuggestions()) && (
          <ul className={this.listStyle()}>
            {this.renderTitleSuggestions()}
            {this.renderCategorySuggestions()}
            {this.renderSubcategorySuggestions()}
            {this.renderMobile()}
          </ul>
        )}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentQuery: inputValue => dispatch(setCurrentQuery(inputValue)),
    dispatch
  }
}

export default connect(null, mapDispatchToProps)(SearchSuggestions)
