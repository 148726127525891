import React from "react"

//icons
import { AiOutlineSearch } from "react-icons/ai"

//images
import HeroImgS from "../../assets/imgs/150dpi/img4_mobile_414x571.jpg"

//style
import styles from "./Hero.module.scss"

const Hero = ({ toggleSearchBar }) => {
  function toggleSearch() {
    window.scrollTo(0, 0)
    toggleSearchBar()
  }

  return (
    <div className={styles.container}>
      <div className={styles.search_container} onClick={toggleSearch}>
        <div className={styles.search}>
          <input className={styles.search_input} type="text" placeholder="Buscar Curso" />
          <AiOutlineSearch />
        </div>
      </div>
      <div className={styles.image_container}>
        <img src={HeroImgS} alt="Pessoa pesquisa no computador" className={styles.image} srcSet={`${HeroImgS} 380w`} />
      </div>
      <div className={styles.slogan}>
        <h2 className={styles.slogan__title}>A melhor opção de curso para você</h2>
        <p>Construa seu futuro com a Apick.</p>
      </div>
    </div>
  )
}

export default Hero
