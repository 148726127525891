import React from "react"

const SuspenseFallback = () => {
  return (
    <div
      className="text-center"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh"
      }}
    >
      <div
        className="spinner-grow text-primary"
        style={{
          width: "17rem",
          height: "17rem",
          backgroundColor: "#f76e2a"
        }}
        role="status"
      >
        <span className="sr-only">Carregando página...</span>
      </div>
    </div>
  )
}

export default SuspenseFallback
