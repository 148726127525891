import React from "react"

//images
import JumboImageDesktop from "../../assets/imgs/72dpi/img1_tablet_768x525.jpg"
import JumboImageMobile from "../../assets/imgs/72dpi/img1_mobile_414x571.jpg"

//style
import styles from "./Jumbo.module.scss"

const Jumbo = ({ isMobile }) => {
  return (
    <section className={styles.container}>
      <img src={isMobile ? JumboImageMobile : JumboImageDesktop} alt="Pessoas estudando no computador" className={styles.image} />
      <p className={styles.slogan}>A melhor opção de curso para você. Construa seu futuro com a apick.</p>
    </section>
  )
}

export default Jumbo
